import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../components/seo'
import Header from '../containers/header'
import Footer from '../containers/footer'
import Hero from '../components/hero'
import ContactForm from '../containers/contact-form'

const IndexPage = ({ pageContext, location }) => {
  const pageData = useStaticQuery(graphql`
    query ContactPageQuery {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl
          defaultImage: image
        }
      }

      page: allContentfulPagina(
        filter: { id: { eq: "f59d77ce-2118-5d8d-a840-f0cc1852b392" } }
      ) {
        edges {
          node {
            headerTitel
            headerSubtitel
            headerButtonTekst
            headerButtonLink
            headerImage {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 900) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const metadata = pageData.site.siteMetadata
  metadata.url = `${metadata.siteUrl}/contact`
  const {
    headerTitel,
    headerSubtitel,
    headerButtonTekst,
    headerButtonLink,
    headerImage,
  } = pageData.page.edges[0].node
  const [flyoutOpen, setFlyoutOpen] = useState(false)

  const flyoutHandler = () => {
    setFlyoutOpen((prevState) => !prevState)
  }

  return (
    <>
      <SEO
        title={`${headerTitel}`}
        description={headerSubtitel || headerTitel}
        image={headerImage?.file?.url}
        metadata={metadata}
      />

      <Header
        transparent
        flyoutOpen={flyoutOpen}
        flyoutHandler={flyoutHandler}
      />
      <main id="main" className="site-wrapper-reveal">
        <Hero
          flyoutHandler={flyoutHandler}
          title={headerTitel}
          description={headerSubtitel}
          buttontext={headerButtonTekst}
          buttonlink={headerButtonLink}
          image={headerImage}
          pageContext={pageContext}
          dot
        />

        <ContactForm />
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
