import React, { useState } from 'react'
import axios from 'axios'
import { graphql, useStaticQuery } from 'gatsby'
import { useForm } from 'react-hook-form/dist/index.ie11'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { Row, Col } from '../../components/ui/wrapper'
import Heading from '../../components/ui/heading'
import Text from '../../components/ui/text'
import Button from '../../components/ui/button'
import Alert from '../../components/ui/alert'
import { Input, Error, FormGroup, Textarea } from '../../components/ui/form'
import { SectionWrap, FormWrapper } from './style'

const ContactForm = () => {
  const apiUrl =
    'https://prod-114.westeurope.logic.azure.com:443/workflows/07db35f292604320abab2f55ce5b4366/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ujtemCE8hWWCwXzLpzKIWZuyl9Qw-x8HS11P7t2nPZU'
  const siteInfo = useStaticQuery(graphql`
    query CantactFormQuery {
      company: allContentfulBedrijf(
        filter: { id: { eq: "ca7bb2ca-5d8a-531e-a88e-80dc8c9e272b" } }
      ) {
        edges {
          node {
            name
            addressCity
            addressNumber
            addressPostalCode
            addressStreet
            poBox
            poCity
            poPostalCode
            phone
            email
          }
        }
      }
    }
  `)
  const contactInformation = siteInfo.company.edges[0].node

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  })

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })

    if (ok) {
      form.reset()
    }
  }

  const onSubmit = (data, event) => {
    const form = event.target
    setServerState({ submitting: true })

    axios({
      method: 'post',
      url: apiUrl,
      data: data,
    })
      .then((response) => {
        setServerState({ submitting: false })
        handleServerResponse(true, 'Uw aanmelding is succesvol verzonden', form)
      })
      .catch((response) => {
        console.log(response.response.data.error)
        setServerState({ submitting: false })
        handleServerResponse(
          false,
          'Er is iets misgegaan met het verzenden van uw aanvraag. Probeer het later nogmaals',
          form,
        )
      })
  }

  return (
    <SectionWrap className="container-xl">
      <FormWrapper>
        <Row>
          <Col md={12}>
            <Heading as="h2" mb="16px">
              Neem contact met ons op
            </Heading>

            <Row>
              <Col className="contact" sm={5}>
                <Heading as="h4" color="#444">
                  Postadres
                </Heading>
                <Text mb={0}>{contactInformation.name}</Text>
                <Text mb={0}>{contactInformation.poBox}</Text>
                <Text>
                  {contactInformation.poPostalCode} {contactInformation.poCity}
                </Text>
                <Text mb={0}>
                  T:{' '}
                  <a href={`tel:${contactInformation.phone}`}>
                    {contactInformation.phone}
                  </a>
                </Text>
                <Text>
                  M:{' '}
                  <a href={`mailto:${contactInformation.email}`}>
                    {contactInformation.email}
                  </a>
                </Text>
              </Col>

              <Col className="contact" sm={5}>
                <Heading as="h4" color="#444">
                  Bezoekadres
                </Heading>
                <Text mb={0}>
                  {contactInformation.addressStreet}{' '}
                  {contactInformation.addressNumber}
                </Text>
                <Text>
                  {contactInformation.addressPostalCode}{' '}
                  {contactInformation.addressCity}
                </Text>
              </Col>
            </Row>

            <Heading as="h3" mt="50px">
              Contactformulier
            </Heading>
            <Text mb="30px">Velden met een * zijn verplicht.</Text>

            {serverState.status && (
              <Alert
                variant={serverState.status.ok ? 'success' : 'danger'}
                mb="30px"
              >
                {serverState.status.ok ? (
                  <FaCheck className="icon" role="img" />
                ) : (
                  <FaTimes className="icon" role="img" />
                )}
                {serverState.status.msg}
              </Alert>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              <Row mb="50px">
                <Col sm={12} mb="20px">
                  <FormGroup>
                    <label htmlFor="email">E-mailadres *</label>
                    <Input
                      bgcolor="white"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Voer uw e-mailadres in"
                      aria-label="Voer uw e-mailadres in"
                      ref={register({
                        required: 'Email is verplicht',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message:
                            'Het ingevoerde emailadress lijkt niet te kloppen',
                        },
                      })}
                      autoComplete="email"
                    />
                    {errors.email && (
                      <Error htmlFor="email">{errors.email.message}</Error>
                    )}
                  </FormGroup>
                </Col>

                <Col sm={12} mb="20px">
                  <FormGroup>
                    <label htmlFor="name">Naam *</label>
                    <Input
                      bgcolor="white"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Naam"
                      aria-label="Naam"
                      ref={register({
                        required: 'Naam is verplicht',
                      })}
                      autoComplete="name"
                    />
                    {errors.name && (
                      <Error htmlFor="name">{errors.name.message}</Error>
                    )}
                  </FormGroup>
                </Col>

                <Col sm={12} mb="20px">
                  <FormGroup>
                    <label htmlFor="message">Bericht *</label>
                    <Textarea
                      bgcolor="white"
                      name="message"
                      id="message"
                      placeholder="Bericht"
                      aria-label="Bericht"
                      ref={register({
                        required: 'Een bericht is verplicht',
                      })}
                    />
                    {errors.message && (
                      <Error htmlFor="message">{errors.message.message}</Error>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row mb="70px">
                <Col>
                  <Button type="submit" disabled={serverState.submitting}>
                    Versturen
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </FormWrapper>
    </SectionWrap>
  )
}

export default ContactForm
